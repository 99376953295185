<template>
    <NavTopBar />
    <div class="sld_cart_top sld_prototype_top" v-if="headerHide < 0">
        <div class="sld_home_top_search_left flex_row_start_center">
            <router-link tag="a" class="sld_logo_wrap flex_row_start_center" :to="`/`">
                <img :src="configInfo.main_site_logo" :onerror='defaultImg' alt />
            </router-link>
            <div class="title_pro">
                <span>{{ title }}</span>
            </div>
        </div>
        <div class="sld_seach_wrap">
            <div class="sld_seach_box1 ld">
                <form class="form" @submit.prevent>
                    <el-select class="form_select" v-if="categories.data.length > 0" v-model="categoriesVal"
                        :placeholder="L['全部分类']" @change="elChange" clearable>
                        <el-option v-for="(item, index) in categories.data" :key="index" :label="item.categoryName"
                            :value="item.categoryId">
                        </el-option>
                    </el-select>
                    <el-select class="form_select" v-else :placeholder="L['全部分类']" clearable v-model="categoriesVal">
                    </el-select>
                    <div class="search_line"></div>
                    <input v-model="keyword" type="text" class="text" autocomplete="off" style="color:rgb(153,153,153);"
                        :placeholder="placeHol" @keyup.enter="search" ref="searchInputCart" @focus="inputFocus"
                        @input="searchAs" @blur="showAs = false" />
                    <input type="submit" :value="L['搜索']" class="button" @mousedown="search" />
                </form>
                <div class=" search_association" id="searchA" v-if="searchList.length && keyword && showAs">
                    <div class="s_a_item" v-for="(item, index) in searchList" :key="index"
                        @mousedown="quickSearch(item)">
                        <!-- 因为click事件与blur事件冲突，blur事件优先，于是换成mousedown -->
                        <div class="txt_col1">{{ item.foreignSpec }}</div>
                        <div class="txt_col1">{{ item.foreignFactory }}</div>
                        <div class="txt_col1">{{ item.goodsNum }}{{L['个产品']}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <router-view v-if="isRouterAlive"></router-view>
    <FooterService />
    <FooterLink />
</template>

<script>
import NavTopBar from "../../components/NavTopBar";
import FooterService from "../../components/FooterService";
import FooterLink from "../../components/FooterLink";
import { useRoute, useRouter } from "vue-router";
import { computed, getCurrentInstance, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
    name: 'Cart',
    components: {
        NavTopBar,
        FooterService,
        FooterLink,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const isRouterAlive = true
        const store = useStore();
        const configInfo = ref(store.state.configInfo)
        const router = useRouter();
        const route = useRoute()
        const keyword = ref('');
        const cateId = ref('')
        const searchList = ref([])
        const headerHide = computed(() => route.path.indexOf('search'))
        const isKeywordChange = ref(false)
        const title = ref('')
        const showAs = ref(false)
        const titleList = ref([
            {
                name: 'optimize,optiHistory,hisDetail',
                nameZ: L['型号转换'],
                placeHol: L['请输入产品名称/型号/物料编码']
            },
            {
                name: 'quickOrder',
                nameZ: L['快速下单'],
                placeHol: L['请输入产品名称/型号/物料编码']
            },
            {
                name: 'reList',
                nameZ: L['国产替代'],
                placeHol: L['请输入关键词']
            },
            {
                name: 'sampleApply,sampleHistory,sampleDetail',
                nameZ: L['样品申请'],
                placeHol: L['请输入关键词']
            }
        ])
        const categories = reactive({ data: [] });
        const categoriesVal = ref('');
        const reHis = ref([])
        const placeHol = ref(L['请输入关键词'])

        watch(() => route.query.keyword, () => {
            keyword.value = route.query.keyword
        })

        watch(() => route.query.replaceKeyword, () => {
            keyword.value = route.query.replaceKeyword
        })

        const search = () => {
            proxy.$refs.searchInputCart.style.color = 'rgb(153,153,153)'
            switch (route.name) {
                case 'reList': {
                    let query = {
                        replaceKeyword: keyword.value
                    }

                    let hisUnit = {
                        foreignSpec: keyword.value,
                        foreignId: 0,
                        categoryId: cateId.value || 0
                    }

                    if (cateId.value) {
                        query.categoryId = cateId.value
                    }

                    setHistoryData(hisUnit, false)

                    if (keyword.value) {
                        router.push({
                            path: '/prototype/replacement/list',
                            query
                        })

                    }

                    break
                }

                default: {
                    let query = {};
                    if (cateId.value === 0 || cateId.value > 0) {

                        let item = categories.data.find(i => i.categoryId == cateId.value);
                        query.categoryId = item.categoryId;
                        query.g = item.grade;
                        query.pid = item.pid;
                    }
                    if (keyword.value) {
                        query.keyword = keyword.value;
                    }
                    if (keyword.value || (categoriesVal.value === 0 || categoriesVal.value > 0)) {
                        router.push({
                            path: `/goods/list`,
                            query
                        });
                    }
                    break
                }
            }

        };

        const inputFocus = () => {
            proxy.$refs.searchInputCart.style.color = '#333'
        }

        // 获取分类
        const getCategories = () => {
            proxy.$get('v3/goods/front/goods/category/topCategory').then(res => {
                categories.data = res.data;
                let obj = res.data.find(i => i.categoryId == cateId.value)
                if (obj) {
                    categoriesVal.value = res.data.find(i => i.categoryId == cateId.value).categoryName
                }
            })
        }

        watch(keyword, (nv, ov) => {

            isKeywordChange.value = true

        })

        watch(() => route.query.categoryId, () => {
            cateId.value = route.query.categoryId || 0
            let obj = categories.data.find(i => i.categoryId == cateId.value)
            if (obj) {
                categoriesVal.value = categories.data.find(i => i.categoryId == cateId.value).categoryName
            }
        })



        const searchAs = () => {
            if (route.name != 'reList') return

            if (!keyword.value) return

            showAs.value = true;
            let param = {
                current: 1,
                pageSize: 50,
                keyword: keyword.value
            }
            if (cateId.value === 0 || cateId.value > 0) param.categoryId1 = cateId.value
            proxy.$get('v3/goods/front/goods/foreign/list', param).then(res => {
                if (res.state == 200) {
                    searchList.value = res.data.list
                }
            })
        }

        const elChange = (e) => {
            cateId.value = e
        }

        //设置缓存
        const setHistoryData = (item, h) => {
            if (h) {
                return
            }
            let tmp_data = [...reHis.value];
            tmp_data.unshift({
                ...item,
                timeStamp: new Date().getTime()
            });
            reHis.value = filterSame(tmp_data).sort((a, b) => b.timeStamp - a.timeStamp).slice(0, 14);
            let stringifyItem = reHis.value.map(i => `${i.foreignSpec}:${i.foreignId}:${i.categoryId}:${i.timeStamp}:h`)
            let history_val_str = stringifyItem.join('~');
            localStorage.setItem("re_his", history_val_str)
        }


        // 过滤不重复的数据
        const filterSame = (tmp_data) => {
            const obj = {}
            const objZero = {}
            let zData = tmp_data.filter(i => i.foreignId == 0)
            let nzData = tmp_data.filter(i => i.foreignId != 0)


            zData.forEach(item => {
                obj[item.foreignSpec] = item
            })

            nzData.forEach(item => {
                obj[item.foreignId] = item
            })

            let objlist = Object.keys(obj).map(function (group) {
                return obj[group];
            });

            let objZeroList = Object.keys(objZero).map(function (group) {
                return obj[group];
            });

            return [...objlist, ...objZeroList]

        }

        const quickSearch = (i, h) => {
            showAs.value = true
            let query = { kId: i.foreignId, replaceKeyword: i.foreignSpec }
            setHistoryData(i, h)
            router.push({
                path: '/prototype/replacement/list',
                query
            })
        }


        router.beforeEach((to, from, next) => {
            // to and from are both route objects. must call `next`.
            titleList.value.forEach(element => {
                if (element.name.indexOf(route.name) > -1) {
                    title.value = element.nameZ
                    placeHol.value = element.placeHol
                }
            });

            next()
        })

        onMounted(() => {

            if (route.query.keyword) {
                keyword.value = route.query.keyword
            }

            if (route.query.replaceKeyword) {
                keyword.value = route.query.replaceKeyword
            }

            if (route.query.categoryId) {
                cateId.value = Number(route.query.categoryId || 0)
            }



            getCategories();
            titleList.value.forEach(element => {
                if (element.name.indexOf(route.name) > -1) {
                    title.value = element.nameZ
                    placeHol.value = element.placeHol
                }
            });
        })

        return {
            configInfo,
            keyword,
            search,
            isRouterAlive,
            L,
            inputFocus,
            categories,
            categoriesVal,
            getCategories,
            title,
            route,
            headerHide,
            searchList,
            searchAs,
            quickSearch,
            placeHol,
            showAs,
            elChange
        }
    }
}
</script>


<style lang="scss">
.sld_prototype_top {
    width: 1200px;
    height: 98px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sld_home_top_search_left {
        position: relative;
        float: left;
        width: 331px;


        .sld_logo_wrap {
            display: block;
            width: 160px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }


        .title_pro {
            border-left: 1px solid $colorI;
            padding-left: 20px;

            span {
                font-size: 25px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #666666;
            }
        }
    }

    .sld_seach_wrap {
        width: 590px;
        padding-top: 4px;


        .sld_seach_box1 {
            width: 580px;
            z-index: 11;
            height: 38px;
            margin-bottom: 3px;
            border: 2px solid $colorMain;
            position: relative;

            .form {
                // background-color: $colorMain;
                height: 34px;
                overflow: hidden;
                position: relative;

                .search_line {
                    width: 2px;
                    height: 18px;
                    background-color: $colorI;
                }


                .text {
                    height: 36px;
                    background-color: #fff;
                    width: 471px;
                    -webkit-appearance: none;
                    -webkit-border-radius: 0;
                    height: 34px;
                    padding: 5px 5px 5px 10px;
                    background-position: 0 -360px;
                    background-color: #fff;
                    background-repeat: repeat-x;
                    line-height: 20px;
                    font-family: arial, "\5b8b\4f53";
                    font-size: 12px;
                    outline: none;
                    border: none;
                }

                input {
                    margin: 0;
                    padding: 0;
                    height: 34px;
                    border: 0;

                    padding-left: 6px;
                    padding-right: 25px;
                    text-indent: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                input::placeholder {

                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgb(153, 153, 153);
                }

                ::-webkit-input-placeholder {

                    margin-left: 20px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgb(153, 153, 153);
                }

                /* 使用webkit内核的浏览器 */
                :-moz-placeholder {

                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgb(153, 153, 153);
                }

                /* Firefox版本19+ */
                :-ms-input-placeholder {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgb(153, 153, 153);
                }

                .button {
                    width: 103px;
                    background: $colorMain;
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    float: right;
                    cursor: pointer;
                    padding-right: 6px;
                }
            }

            .hot_search_wrap {
                height: 30px;
                line-height: 30px;
                color: #999;
                overflow: hidden;
                white-space: nowrap;

                strong {
                    float: left;
                    font-weight: 400;
                }

                a {
                    color: $colorTitle2;

                    &:link,
                    &:visited {
                        float: left;
                        margin-right: 10px;
                    }

                    &:hover {
                        color: $colorMain;
                    }
                }
            }
        }
    }

    .form {
        display: flex;
        align-items: center;
    }

    .search_association {
        background: #fff;
        position: absolute;
        top: 43px;
        overflow: hidden;
        position: absolute;
        left: 0;
        width: 492px;
        max-height: 270px;
        overflow-y: auto;
        border: 1px solid $colorMain;
        background: #fff;
        z-index: 99;

        .s_a_item {
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            padding: 15px;
            cursor: pointer;
            font-size: 12px;
            -webkit-font-smoothing: antialiased;
            color: $colorTitle2;
            border-bottom: 1px dashed #999;

            &:last-child {
                border-bottom: none;
            }

            .txt_col1 {
                width: 150px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: center;
            }


            &:hover {
                background-color: #f7f7f7;
            }
        }
    }

    .el-select {
        height: 34px;
        line-height: 34px;
    }

    .el-select .el-input {
        width: 100px;
        height: 34px;
    }

    .el-input--suffix .el-input__inner {
        height: 34px;
        padding-right: 0;
        border-radius: 0;
        padding-bottom: 1px;
        text-indent: 10px;
    }

    .el-input__suffix-inner {
        position: relative;
        bottom: 3px;
    }
}
</style>